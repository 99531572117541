<template>
  <v-dialog v-model="dialog" width="914" @keydown.esc="dialog = false">
    <template v-slot:activator="{ on }">
      <a
        v-on="isBrandSectionDisabled ? null : on"
        :class="
          isBrandSectionDisabled
            ? 'Body-1-Black-Disabled-Left hoverline'
            : 'Body-1-Primary-Left hoverline'
        "
        >Choose Drop-off Locations</a
      >
      <div class="Caption-Black-Medium-Emphasis-Left">
        Selected Locations: {{ selectedLocations.length }}
      </div>
      <v-text-field
        disabled
        :value="selectedLocations.length"
        :rules="rules"
        class="custom-validation pa-0 mt-3"
      />
    </template>
    <dropoff-locations-dialog
      :selectedLocations="selectedLocations"
      :siteDeliveryDestinations="siteDeliveryDestinations"
      :isTimeslotUserDefined="isTimeslotUserDefined"
      @cancelDialog="dialog = false"
      @updateSelectedLocations="updateSelectedLocations"
    />
  </v-dialog>
</template>

<script>
import rules from '@/rules';
import Dialog from '@/components/BrandSettings/DropoffLocations/Dialog';

export default {
  components: { 'dropoff-locations-dialog': Dialog },
  props: {
    selectedLocations: {
      type: Array,
      required: true,
    },
    isTimeslotUserDefined: {
      type: Boolean,
      required: true,
    },
    isBrandSectionDisabled: {
      type: Boolean,
    },
    siteDeliveryDestinations: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    rules: [rules.min(1, 'There must be at least one dropoff location')],
  }),
  methods: {
    updateSelectedLocations(newSelectedLocations) {
      this.dialog = false;
      this.$emit('updateSelectedLocations', newSelectedLocations);
    },
  },
};
</script>
