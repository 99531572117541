import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        VFlex,
        { attrs: { xs8: "" } },
        [
          _c(VTextField, {
            staticClass: "search-locations",
            attrs: {
              label: "Search Locations",
              "prepend-inner-icon": "mdi-magnify",
              clearable: ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        VFlex,
        { attrs: { xs12: "" } },
        [
          _c(
            VLayout,
            { staticClass: "list-container", attrs: { row: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs6: "" } },
                [
                  _c("p", { staticClass: "H6-Secondary-Left" }, [
                    _vm._v("All Locations")
                  ]),
                  _c(
                    VList,
                    { staticClass: "list-panel" },
                    [
                      _c(
                        VListTile,
                        { staticClass: "macro-list-tile" },
                        [
                          _c(
                            VListTileAction,
                            { staticClass: "list-action" },
                            [
                              _c(VCheckbox, {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  value: _vm.isSelectAll
                                },
                                on: { change: _vm.selectAll }
                              })
                            ],
                            1
                          ),
                          _c(
                            VListTileContent,
                            { staticClass: "list-content" },
                            [
                              _c(VListTileTitle, [
                                _c(
                                  "p",
                                  { staticClass: "list-macro list-item" },
                                  [_vm._v("Select All")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.filterBy(
                          _vm.sortedSiteDeliveryDestinations,
                          _vm.search,
                          "name"
                        ),
                        function(location, i) {
                          return _c(
                            VListTile,
                            {
                              key: location + "-" + i,
                              staticClass: "list-tile"
                            },
                            [
                              _c(
                                VListTileAction,
                                { staticClass: "list-action" },
                                [
                                  _c(VCheckbox, {
                                    attrs: {
                                      "input-value": _vm.isChecked(
                                        location.name
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.checkboxClicked(
                                          location.name
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VListTileContent,
                                { staticClass: "list-content" },
                                [
                                  _c(
                                    VListTileTitle,
                                    { staticClass: "list-item" },
                                    [
                                      _c("p", { staticClass: "list-text" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(location.name) +
                                            "\n                  "
                                        ),
                                        location.foodlocker
                                          ? _c("img", {
                                              staticClass: "foodlocker-icon",
                                              attrs: {
                                                src: require("./foodlocker.svg"),
                                                width: "106px",
                                                alt: "FOODLOCKER"
                                              }
                                            })
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs6: "" } },
                [
                  _c("p", { staticClass: "H6-Secondary-Left" }, [
                    _vm._v(
                      "Locations Selected (" +
                        _vm._s(_vm.selectedLocations.length) +
                        ")"
                    )
                  ]),
                  _c(
                    VList,
                    { staticClass: "list-panel" },
                    [
                      _c(
                        VListTile,
                        { staticClass: "macro-list-tile" },
                        [
                          _c(
                            VListTileAction,
                            { staticClass: "list-action" },
                            [
                              _c(VIcon, { on: { click: _vm.removeAll } }, [
                                _vm._v("mdi-close-circle")
                              ])
                            ],
                            1
                          ),
                          _c(
                            VListTileContent,
                            { staticClass: "list-content" },
                            [
                              _c(VListTileTitle, [
                                _c(
                                  "p",
                                  { staticClass: "list-macro list-item" },
                                  [_vm._v("Remove All")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.selectedSiteDeliveryDestinations, function(
                        location,
                        i
                      ) {
                        return _c(
                          VListTile,
                          { key: location + "-" + i, staticClass: "list-tile" },
                          [
                            _c(
                              VListTileAction,
                              { staticClass: "list-action" },
                              [
                                _c(
                                  VIcon,
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.removeLocation(location.name)
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-close-circle")]
                                )
                              ],
                              1
                            ),
                            _c(
                              VListTileContent,
                              { staticClass: "list-content" },
                              [
                                _c(
                                  VListTileTitle,
                                  { staticClass: "list-item" },
                                  [
                                    _c("p", { staticClass: "list-item" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(location.name) +
                                          "\n                  "
                                      ),
                                      location.foodlocker
                                        ? _c("img", {
                                            staticClass: "foodlocker-icon",
                                            attrs: {
                                              src: require("./foodlocker.svg"),
                                              width: "106",
                                              alt: "FOODLOCKER"
                                            }
                                          })
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCardText,
        {
          staticClass:
            "Body-3-Selected-On-Surface-Medium-Emphasis-Left assign-tip"
        },
        [
          _vm._v(
            "If you would like to manage(add, edit) drop-off locations, please go the\n    "
          ),
          _c("strong", [_vm._v("Site Configuration")]),
          _vm._v(".")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }