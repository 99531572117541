import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { staticClass: "H4-Secondary-Center dropoff-title" }, [
        _vm._v("Choose Drop-off Locations")
      ]),
      _c(
        VCardText,
        { staticClass: "card-text" },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VCardText,
                {
                  staticClass:
                    "Body-2-Selected-On-Surface-Medium-Emphasis-Left assign-subtitle"
                },
                [_vm._v("Select locations to apply to this station.")]
              )
            ],
            1
          ),
          _c("assign-dropoff-locations", {
            attrs: {
              siteDeliveryDestinations: _vm.siteDeliveryDestinations,
              selectedLocations: _vm.newSelectedLocations
            },
            on: { updateSelectedLocations: _vm.updateNewSelectedLocations }
          })
        ],
        1
      ),
      _c(
        VCardActions,
        [
          _c(VSpacer),
          _c(
            VBtn,
            {
              attrs: { color: "primary", flat: "" },
              on: { click: _vm.handleDialogCancel }
            },
            [_vm._v("cancel")]
          ),
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                flat: "",
                disabled: !_vm.newSelectedLocations.length
              },
              on: { click: _vm.handleDialogSave }
            },
            [_vm._v("\n      continue\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }