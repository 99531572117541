<template>
  <v-card>
    <v-card-title class="H4-Secondary-Center dropoff-title">Choose Drop-off Locations</v-card-title>
    <v-card-text class="card-text">
      <v-flex xs12>
        <v-card-text class="Body-2-Selected-On-Surface-Medium-Emphasis-Left assign-subtitle"
          >Select locations to apply to this station.</v-card-text
        >
      </v-flex>
      <assign-dropoff-locations
        :siteDeliveryDestinations="siteDeliveryDestinations"
        :selectedLocations="newSelectedLocations"
        @updateSelectedLocations="updateNewSelectedLocations"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" flat @click="handleDialogCancel">cancel</v-btn>
      <v-btn
        color="primary"
        flat
        @click="handleDialogSave"
        :disabled="!newSelectedLocations.length"
      >
        continue
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AssignDropoffLocations from '@/components/BrandSettings/AssignDropoffLocations';

export default {
  components: {
    'assign-dropoff-locations': AssignDropoffLocations,
  },
  props: {
    selectedLocations: {
      type: Array,
      required: true,
    },
    siteDeliveryDestinations: {
      type: Array,
      required: true,
    },
    isTimeslotUserDefined: {
      type: Boolean,
      required: true,
    },
    open: Boolean,
  },
  data() {
    return {
      newSelectedLocations: this.selectedLocations,
    };
  },
  methods: {
    handleDialogCancel() {
      this.newSelectedLocations = this.selectedLocations;
      this.$emit('cancelDialog');
    },
    handleDialogSave() {
      this.$emit('updateSelectedLocations', {
        locations: this.newSelectedLocations,
        modifications: this.modifications,
      });
    },
    updateNewSelectedLocations(newSelectedLocations) {
      this.newSelectedLocations = newSelectedLocations;
    },
  },
  watch: {
    selectedLocations: {
      immediate: true,
      handler() {
        if (this.newSelectedLocations.length === 0) {
          this.newSelectedLocations = this.selectedLocations;
        }
      },
    },
  },
};
</script>

<style scoped>
.assign-subtitle {
  padding-left: 8px;
  padding-bottom: 32px;
}

.location-btn {
  border-radius: 0 6px 6px 0;
  height: 56px;
  min-width: 56px;
  margin: 0;
}

.location-list {
  border: 1px solid #757575;
  height: 270px;
  overflow-x: scroll;
  padding: 0 0 10px 16px;
}

.location-tile >>> .v-list__tile {
  border-bottom: 1px solid #757575;
  cursor: pointer;
  padding: 0 16px 0 0;
}

.location-tile >>> .v-list__tile:hover {
  background: initial;
}

.dropoff-title {
  margin: 0 0 0 8px;
  padding: 24px 0 0 24px;
}

.input >>> .v-input__control > .v-input__slot {
  border: 1px solid rgba(0, 0, 0, 0.32);
  border-radius: 6px 0 0 6px !important;
}

.input >>> .v-input__control > .v-input__slot input {
  margin-top: 0;
}

.removal-notice {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.5px;
  font-size: 18px;
  margin-top: 8px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.sortable-drag {
  opacity: 0;
  cursor: pointer;
}

.card-text {
  padding-bottom: 0;
}
</style>
