<template>
  <v-layout row wrap>
    <v-flex xs8>
      <v-text-field
        label="Search Locations"
        prepend-inner-icon="mdi-magnify"
        v-model="search"
        clearable
        class="search-locations"
      />
    </v-flex>
    <v-flex xs12>
      <v-layout row class="list-container">
        <v-flex xs6>
          <p class="H6-Secondary-Left">All Locations</p>
          <v-list class="list-panel">
            <v-list-tile class="macro-list-tile">
              <v-list-tile-action class="list-action">
                <v-checkbox :disabled="isDisabled" :value="isSelectAll" @change="selectAll" />
              </v-list-tile-action>
              <v-list-tile-content class="list-content">
                <v-list-tile-title>
                  <p class="list-macro list-item">Select All</p>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-for="(location, i) of filterBy(sortedSiteDeliveryDestinations, search, 'name')"
              :key="`${location}-${i}`"
              class="list-tile"
            >
              <v-list-tile-action class="list-action">
                <v-checkbox
                  @change="checkboxClicked(location.name)"
                  :input-value="isChecked(location.name)"
                />
              </v-list-tile-action>
              <v-list-tile-content class="list-content">
                <v-list-tile-title class="list-item">
                  <p class="list-text">
                    {{ location.name }}
                    <img
                      class="foodlocker-icon"
                      v-if="location.foodlocker"
                      :src="require('./foodlocker.svg')"
                      width="106px"
                      alt="FOODLOCKER"
                    />
                  </p>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-flex>
        <v-flex xs6>
          <p class="H6-Secondary-Left">Locations Selected ({{ selectedLocations.length }})</p>
          <v-list class="list-panel">
            <v-list-tile class="macro-list-tile">
              <v-list-tile-action class="list-action">
                <v-icon @click="removeAll">mdi-close-circle</v-icon>
              </v-list-tile-action>
              <v-list-tile-content class="list-content">
                <v-list-tile-title>
                  <p class="list-macro list-item">Remove All</p>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-for="(location, i) of selectedSiteDeliveryDestinations"
              :key="`${location}-${i}`"
              class="list-tile"
            >
              <v-list-tile-action class="list-action">
                <v-icon @click="removeLocation(location.name)">mdi-close-circle</v-icon>
              </v-list-tile-action>
              <v-list-tile-content class="list-content">
                <v-list-tile-title class="list-item">
                  <p class="list-item">
                    {{ location.name }}
                    <img
                      class="foodlocker-icon"
                      v-if="location.foodlocker"
                      :src="require('./foodlocker.svg')"
                      width="106"
                      alt="FOODLOCKER"
                    />
                  </p>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-card-text class="Body-3-Selected-On-Surface-Medium-Emphasis-Left assign-tip"
      >If you would like to manage(add, edit) drop-off locations, please go the
      <strong>Site Configuration</strong>.</v-card-text
    >
  </v-layout>
</template>

<script>
import filters from 'vue2-filters';

export default {
  mixins: [filters.mixin],
  props: {
    siteDeliveryDestinations: {
      type: Array,
      required: true,
    },
    selectedLocations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      newSelectedLocations: this.selectedLocations.reduce((selected, name) => {
        selected[name] = true;
        return selected;
      }, {}),
      siteDeliveryDestinationsObj: this.siteDeliveryDestinations.reduce((acc, destination) => {
        acc[destination.name] = destination;
        return acc;
      }, {}),
    };
  },
  computed: {
    isDisabled() {
      return !!this.search;
    },
    isSelectAll() {
      return this.siteDeliveryDestinations.every(
        (location) => this.newSelectedLocations[location.name],
      );
    },
    sortedSiteDeliveryDestinations() {
      return [...this.siteDeliveryDestinations].sort((locationA, locationB) =>
        locationA.name.localeCompare(locationB.name),
      );
    },
    selectedSiteDeliveryDestinations() {
      return this.selectedLocations
        .map((location) => {
          const newDeliveryDestination = this.siteDeliveryDestinationsObj[location];
          if (newDeliveryDestination) {
            return newDeliveryDestination;
          }
          // convert previous version of drop off locations
          // into obj with previous foodlocker logic added
          return {
            name: location,
            foodlocker: location.toUpperCase().includes('FOOD LOCKER'),
          };
        })
        .sort((locationA, locationB) => locationA.name.localeCompare(locationB.name));
    },
  },
  methods: {
    isChecked(name) {
      return this.newSelectedLocations[name];
    },
    selectAll() {
      if (this.isSelectAll) {
        this.removeAll();
      } else {
        this.siteDeliveryDestinations.forEach((location) => {
          this.newSelectedLocations[location.name] = true;
        });
        this.emitSelectedLocations();
      }
    },
    removeAll() {
      this.newSelectedLocations = {};
      this.emitSelectedLocations();
    },
    removeLocation(name) {
      this.newSelectedLocations[name] = false;
      this.emitSelectedLocations();
    },
    checkboxClicked(name) {
      this.newSelectedLocations[name] = !this.newSelectedLocations[name];
      this.emitSelectedLocations();
    },
    emitSelectedLocations() {
      const selectedLocations = Object.keys(this.newSelectedLocations).filter(
        (name) => this.newSelectedLocations[name],
      );
      this.$emit('updateSelectedLocations', selectedLocations);
    },
  },
  watch: {
    selectedLocations: {
      immediate: true,
      handler() {
        this.newSelectedLocations = {};
        this.selectedLocations.forEach((name) => {
          this.newSelectedLocations[name] = true;
        });
      },
    },
    siteDeliveryDestinations: {
      immediate: true,
      handler() {
        this.siteDeliveryDestinationsObj = this.siteDeliveryDestinations.reduce(
          (acc, destination) => {
            acc[destination.name] = destination;
            return acc;
          },
          {},
        );
      },
    },
  },
};
</script>

<style scoped>
.assign-title {
  padding: 0;
}

.assign-subtitle {
  padding-left: 8px;
  padding-bottom: 32px;
}

.assign-tip {
  padding: 0;
  padding-top: 16px;
}

.search-locations {
  padding-top: 0;
  margin-top: 0;
  height: 56px;
}

.list-panel {
  border: 1px solid #000;
  height: 300px;
  margin-right: 8px;
  overflow-x: hidden;
  overflow-y: auto;
}

.list-panel >>> .v-input__slot {
  margin-bottom: 0;
}
.macro-list-tile >>> .v-list__tile {
  height: auto;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
}

.list-tile >>> .v-list__tile {
  height: auto;
  align-items: flex-start;
  padding-bottom: 16px;
}

.list-content {
  overflow: visible;
}

.list-action {
  min-width: 48px;
  padding: 8px;
  padding-top: 0;
}

.list-macro {
  font-weight: bold;
  margin-bottom: 32px;
}

.list-item {
  color: #000;
  margin-bottom: 0;
  overflow-wrap: break-word;
  overflow: visible;
  white-space: normal;
  height: auto;
}

.list-text {
  margin-bottom: 0;
}

.foodlocker-icon {
  vertical-align: middle;
}
</style>
