import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { width: "914" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          _vm.dialog = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "a",
                _vm._g(
                  {
                    class: _vm.isBrandSectionDisabled
                      ? "Body-1-Black-Disabled-Left hoverline"
                      : "Body-1-Primary-Left hoverline"
                  },
                  _vm.isBrandSectionDisabled ? null : on
                ),
                [_vm._v("Choose Drop-off Locations")]
              ),
              _c("div", { staticClass: "Caption-Black-Medium-Emphasis-Left" }, [
                _vm._v(
                  "\n      Selected Locations: " +
                    _vm._s(_vm.selectedLocations.length) +
                    "\n    "
                )
              ]),
              _c(VTextField, {
                staticClass: "custom-validation pa-0 mt-3",
                attrs: {
                  disabled: "",
                  value: _vm.selectedLocations.length,
                  rules: _vm.rules
                }
              })
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("dropoff-locations-dialog", {
        attrs: {
          selectedLocations: _vm.selectedLocations,
          siteDeliveryDestinations: _vm.siteDeliveryDestinations,
          isTimeslotUserDefined: _vm.isTimeslotUserDefined
        },
        on: {
          cancelDialog: function($event) {
            _vm.dialog = false
          },
          updateSelectedLocations: _vm.updateSelectedLocations
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }